import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';
import { PhotoCamera } from 'components/base/PhotoCamera';

type AddDeliveryPhotoModalProps = {
  show: boolean;
  onCancel: () => void;
  onAccept: (s: string) => void;
}

export const AddDeliveryPhotoModal: FC<AddDeliveryPhotoModalProps> = ({
  show,
  onCancel,
  onAccept,
}) => {
  const { t } = useTranslation();
  const [c, setC] = useState<string>();

  useEffect(() => {
    if (c) {
      onAccept(c);
    }
  }, [c, onAccept]);

  return (
    <Modal
      buttons={[
        {
          onClick: () => {
            onCancel();
          },
          text: t('common.cancel'),
        },
      ]}
      className="appointment-modal"
      show={show}
      showCancel={false}
      title={t('article.takeDeliveredPhoto')}
      onHide={() => false}
    >
      <PhotoCamera
        // eslint-disable-next-line no-console
        onConfirmPhoto={(capture) => setC(capture)}
      />
    </Modal>
  );
};
