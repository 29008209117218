import { IMasterDataDto } from 'models/MasterData';

import { BudgetQuestionCodes } from '../data/budgetQuestionCodes';
import { ArticleCollectionTypes, ArticleType, ClientType } from '../models/Article/types';
import { IAudit } from '../models/Audit/types';

import { BudgetsTrackingItem } from './BudgetsTrackingItem';
import { IBudgetsTrackingItem } from './BudgetsTrackingItem/types';

export interface ApiPrice {
  price: number;
  vat: number;
  idClient: string;
}

export interface ApiArticle extends IAudit {
  code: string;
  description: string;
  image: string;
  prices: Array<ApiPrice>;
  questionCode: BudgetQuestionCodes;
  subType: ArticleCollectionTypes;
  type: ArticleType;
}

export interface ApiArticleQuestionResponse {
  articles: {
    ARTICLE?: Array<ApiArticle>;
    CONCESSION?: Array<ApiArticle>;
    TAX?: Array<ApiArticle>;
    TRANSFER?: Array<ApiArticle>;
  };
}

export interface ApiBudgetsTrackingResponse {
  list: IBudgetsTrackingItem[];
  numberOfItems: number;
  numberOfPages: number;
}

export enum MasterDataQuestionType {
  CEREMONY = 'CEREMONY',
  CEREMONY_TYPE = 'CEREMONY_TYPE',
  COFFIN = 'COFFIN',
  CREMATION = 'CREMATION',
  DEATH = 'DEATH',
  DEFAULT = 'DEFAULT',
  INSURANCE = 'INSURANCE',
  INTERMENT = 'INTERMENT',
  OBITUARY = 'OBITUARY',
  PACEMAKER = 'PACEMAKER',
  VIGIL = 'VIGIL'
}

export interface MasterDataWithWork extends IMasterDataDto {
  work: boolean;
}

export interface BudgetsTrackingList {
  list: BudgetsTrackingItem[];
  numberOfItems: number;
  numberOfPages: number;
}

export interface ApiBudgetSumarizeResponse {
  articles: {
    amount: number;
    code: string;
    description: string;
    id: number;
    price: number;
    questionCode: BudgetQuestionCodes;
    subType: ArticleCollectionTypes;
    totalCost: number;
    type: ArticleType;
    vat: number;
  }[],
  companyName: string;
  type: ClientType;
  clientServices: number;
  totalCost: number;
  totalVat: number;
}
