import { BudgetQuestionCodes } from 'modules/budget/data/budgetQuestionCodes';

import { ArticleCollectionTypes, ArticleType } from '../Article/types';
import { Audit } from '../Audit';

import { IBudgetSumarizeArticle } from './types';

export class BudgetSumarizeArticle extends Audit implements IBudgetSumarizeArticle {
  constructor(params: IBudgetSumarizeArticle) {
    super(params);
    this.amount = params.amount;
    this.code = params.code;
    this.description = params.description;
    this.price = params.price;
    this.questionCode = params.questionCode;
    this.subType = params.subType;
    this.totalCost = params.totalCost;
    this.type = params.type;
    this.vat = params.vat;
  }

  amount: number;

  code: string;

  description: string;

  price: number | null;

  questionCode: BudgetQuestionCodes | null;

  subType?: ArticleCollectionTypes | undefined;

  totalCost: number;

  type?: ArticleType | null | undefined;

  vat?: number | null | undefined;
}
