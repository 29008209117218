import { Form, Formik, FormikProps } from 'formik';
import React, {
  FC, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';
import Input from 'components/base/Input';
import { Row } from 'components/base/Row';
import Tooltip from 'components/base/Tooltip';

import { useBudget } from 'modules/budget/hooks/useBudget';

import { ArticlePriceComponent } from './types';
import { useArticlePriceValidation } from './useArticlePriceValidation';

import './index.scss';

export const ArticlePrice: FC<ArticlePriceComponent> = ({ article, onPriceUpdated, onCancel }) => {
  const { t } = useTranslation();
  const { SetArticlePrice } = useBudget();
  const { validate } = useArticlePriceValidation();
  const [price, setPrice] = useState<number>();

  useEffect(
    () => {
      setPrice(article.price || 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div className="f-article-price">
      <Formik
        initialValues={{
          price,
        }}
        validationSchema={validate}
        enableReinitialize
        validateOnChange
        onSubmit={(values) => {
          if (values.price !== undefined && article.id && article.type) {
            SetArticlePrice(
              article.id,
              article.type,
              parseFloat(values.price.toString().replace(',', '.')),
            );
            onPriceUpdated();
          }
          return Promise.resolve();
        }}
      >
        {({
          setFieldValue, values, errors, isValid,
        }: FormikProps<{price?: number}>) => (
          <Form autoComplete="off">
            <Row justifyContent="flex-end" style={{ alignItems: 'center' }}>
              <Tooltip
                key="cancel-change-price"
                content={t('common.cancel')}
              >
                <Button
                  color="transparent"
                  type="button"
                  onClick={onCancel}
                >
                  <CustomIcon icon={OtherTypes.CANCEL_FLOWERS} />
                </Button>
              </Tooltip>
              <Input
                error={errors.price}
                id="price"
                name="price"
                placeholder={t('budget.wizard.summary.unitPrice')}
                rightAddon={<React.Fragment>€</React.Fragment>}
                type="text"
                value={values.price?.toString().replace('.', ',')}
                onBlur={() => false}
                onChange={(val) => setFieldValue('price', val)}
              />
              <Tooltip
                key="confirm-price"
                content={t('budget.wizard.summary.confirmPrice')}
              >
                <Button
                  color="transparent"
                  disabled={!isValid}
                  type="submit"
                >
                  <CustomIcon icon={OtherTypes.ACCEPT_FLOWERS_REQUEST} />
                </Button>
              </Tooltip>
            </Row>
          </Form>
        )}
      </Formik>
    </div>

  );
};
