import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDateForRequest } from 'utils/dateManager';
import { showErrorToast, showSuccessToast } from 'utils/toasts';

import { orderLineController } from 'config/apiFunus';
import {
  ArticleStatusRequestDtoEvent,
  MasterDataDto,
  OrderLine,
  DisagreementRequestDto,
} from 'config/apiFunus/generated/data-contracts';

import { ArticlesHook } from './types';
import { useArticleDraft } from './useArticleDraft';
import { useCommercialOverviewUsingGet } from './useCommercialOverviewUsingGet';
import { useGetArticleByCategoryUsingGet } from './useGetArticleByCategoryUsingGet';
import { useGetArticleInfoUsingPost } from './useGetArticleInfoUsingPost';
import { useGetArticleSupplierUsingGet } from './useGetArticleSupplierUsingGet';
import { useGetArticleTrackingUsingPost } from './useGetArticleTrackingUsingPost';
import { useGetCategoryByArticleUsingGet } from './useGetCategoryByArticleUsingGet';
import { useGetEntryChannelsUsingGet } from './useGetEntryChannelsUsingGet';

export const useArticles = (): ArticlesHook => {
  const { t } = useTranslation();
  const errorMessage = useCallback(
    (messageId) => {
      showErrorToast(t(messageId));
    },
    [t],
  );

  const {
    articleDraft,
    getArticleDraft,
    acceptDraft,
  } = useArticleDraft(errorMessage);

  const {
    articleDetail,
    getArticleDetail,
  } = useGetArticleInfoUsingPost(errorMessage);

  const {
    comercialBase64,
    getComercialPdf,
  } = useCommercialOverviewUsingGet(errorMessage);

  const {
    articles,
    fetchArticles,
  } = useGetArticleTrackingUsingPost(errorMessage);

  const {
    articleCategories,
    fetchArticleCategories,
  } = useGetCategoryByArticleUsingGet(errorMessage);

  const {
    articlesByCategory,
    fetchArticleByCategory,
  } = useGetArticleByCategoryUsingGet(errorMessage);

  const {
    articleProviders,
    fetchArticleProviders,
  } = useGetArticleSupplierUsingGet();

  const {
    articleRequestTypes,
    fetchArticleRequestTypes,
  } = useGetEntryChannelsUsingGet(errorMessage);

  const [articleExternalFlowerTypes, setArticleExternalFlowerTypes] = useState<MasterDataDto[]>([]);

  const successMessage = useCallback(
    (messageId) => {
      showSuccessToast(t(messageId));
    },
    [t],
  );

  const updateArticleStatus = useCallback(
    async (
      code: number,
      event: ArticleStatusRequestDtoEvent,
      image?: string,
    ) => {
      await orderLineController
        .updateStatusUsingPut(code, { event, image })
        .then((response) => response)
        .catch((error) => {
          showErrorToast(error.message
            ? error.message
            : t('article.flowers.statusUpdatedOK'));
          throw error;
        });
    },
    [t],
  );

  const createDisagreement = useCallback(
    (
      articleId: number,
      disagreement: DisagreementRequestDto,
    ): Promise<void> => orderLineController
      .createDisagreementUsingPost(articleId, disagreement)
      .then(() => {
        successMessage('article.createDisagreementOK');

        return undefined;
      })
      .catch((error) => {
        errorMessage(error.message);
        throw error;
      }),
    [errorMessage, successMessage],
  );

  const fetchExternalFlowerTypes = () => Promise.resolve([
    { code: 'anyExternalFlowerTypesCode', description: 'anyExternalFlowerTypesDescription' },
  ])
    .then((response) => {
      setArticleExternalFlowerTypes(response);

      return response;
    })
    .catch((error) => {
      errorMessage(error.message);
      throw error;
    });

  const createArticle = (
    recordArticle: OrderLine,
  ) => orderLineController
    .addArticleUsingPost({
      ...recordArticle,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      date: formatDateForRequest(recordArticle.date || '') as any,
    })
    .then((response) => {
      successMessage('article.createArticleRequestOK');
      try {
        return Array.isArray(response) ? response[0] : response;
      } catch {
        return response;
      }
    })
    .catch((error) => {
      errorMessage(error.message);
      throw error;
    });

  return {
    acceptDraft,
    articleCategories,
    articleDetail,
    articleDraft,
    articleExternalFlowerTypes,
    articleProviders,
    articleRequestTypes,
    articles,
    articlesByCategory,
    comercialBase64,
    createArticle,
    createDisagreement,
    fetchArticleByCategory,
    fetchArticleCategories,
    fetchArticleProviders,
    fetchArticleRequestTypes,
    fetchArticles,
    fetchExternalFlowerTypes,
    getArticleDetail,
    getArticleDraft,
    getComercialPdf,
    updateArticleStatus,
  };
};
