import classNames from 'classnames';
import { FormikProps, getIn } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Datepicker from 'components/base/Datepicker';
import Select from 'components/base/Select';
import SelectCountryProvinceCity from 'components/base/SelectCountryProvinceCity';
import Textarea from 'components/base/Textarea';

import useDeposit from 'hooks/useDeposit';
import { IMasterDataDto } from 'models/MasterData';
import { SaveService } from 'models/MasterService';
import { SaveRecord } from 'models/Record';

type DepositProps = {
  className?: string;
  disabled?: boolean;
  formikProps: FormikProps<SaveService>;
  record?: SaveRecord;
};

const DepositForm: FC<DepositProps> = ({
  className,
  disabled,
  formikProps: {
    errors, handleBlur, isSubmitting, setFieldValue, values,
  },
  record,
}) => {
  const { deposits, normalizedDeposits } = useDeposit(
    values.depositServiceDto?.address?.city,
  );
  const { t } = useTranslation();
  return (
    <div className={classNames('deposit-form', className)}>
      <fieldset>
        <legend>{t('service.DEPOSIT')}</legend>
        <SelectCountryProvinceCity
          key="address"
          cityProps={{
            error: getIn(errors, 'depositServiceDto.address.city'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'address.c',
            onBlur: handleBlur,
            onChange: (val: string) => {
              setFieldValue('depositServiceDto.address.city', val);
              setFieldValue('depositServiceDto.address.crematorium', undefined);
              setFieldValue('depositServiceDto.address.oven', undefined);
              setFieldValue('depositServiceDto.address.deposit', undefined);
            },
            placeholder: t('common.city'),
            value: values.depositServiceDto?.address?.city || '',
          }}
          countryProps={{
            error: getIn(errors, 'depositServiceDto.address.country'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'address.co',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('depositServiceDto.address.country', val),
            placeholder: t('common.country'),
            value: values.depositServiceDto?.address?.country || '',
          }}
          disabled={disabled || isSubmitting}
          initialize={false}
          provinceProps={{
            error: getIn(errors, 'depositServiceDto.address.province'),
            getLabel: ({ description }) => description,
            getValue: ({ code }) => code,
            name: 'address.province',
            onBlur: handleBlur,
            onChange: (val: string) => setFieldValue('depositServiceDto.address.province', val),
            placeholder: t('common.province'),
            value: values.depositServiceDto?.address?.province || '',
          }}
        />
        <Select<IMasterDataDto>
          key="deposit"
          disabled={
            isSubmitting || disabled || !values.depositServiceDto?.address?.city
          }
          error={getIn(errors, 'depositServiceDto.deposit')}
          getLabel={({ description }) => description}
          getValue={({ code }) => code}
          name="deposit"
          options={deposits}
          placeholder={t('service.deposit.deposit')}
          value={
            values.depositServiceDto?.deposit
              ? normalizedDeposits[values.depositServiceDto?.deposit]
              : undefined
          }
          searchable
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('depositServiceDto.deposit', val?.code)}
        />
        <Datepicker
          key="entryDate"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'depositServiceDto.entryDate')}
          minDate={
            record?.deathData?.deathDate && new Date(record.deathData.deathDate)
          }
          name="entryDate"
          placeholder={t('service.deposit.entryDate')}
          selected={values.depositServiceDto?.entryDate}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('depositServiceDto.entryDate', val)}
        />
        <Datepicker
          key="entryTime"
          disabled={disabled || isSubmitting}
          error={getIn(errors, 'depositServiceDto.entryTime')}
          name="entryTime"
          placeholder={t('service.deposit.entryTime')}
          selected={values.depositServiceDto?.entryTime}
          showTimeSelectOnly
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('depositServiceDto.entryTime', val)}
        />
        <Datepicker
          key="exitDate"
          error={getIn(errors, 'depositServiceDto.exitDate')}
          name="exitDate"
          placeholder={t('common.exitDate')}
          selected={values.depositServiceDto?.exitDate}
          disabled
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('depositServiceDto.exitDate', val)}
        />
        <Datepicker
          key="exitTime"
          error={getIn(errors, 'depositServiceDto.exitTime')}
          name="exitTime"
          placeholder={t('common.exitTime')}
          selected={values.depositServiceDto?.exitTime}
          disabled
          showTimeSelectOnly
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('depositServiceDto.exitTime', val)}
        />
        <Textarea
          disabled={isSubmitting || disabled}
          error={getIn(errors, 'depositServiceDto.comment')}
          name="depositServiceDto.comment"
          placeholder={t('common.observations')}
          value={values.depositServiceDto?.comment || ''}
          onBlur={handleBlur}
          onChange={(val) => setFieldValue('depositServiceDto.comment', val)}
        />
      </fieldset>
    </div>
  );
};

export default DepositForm;
