import React, {
  FC, useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/base/Tooltip';

import { questionsWithMinInvoiceAmount } from '../../../data/questionsWithMinInvoiceAmount';
import { ArticlePrice } from '../ArticlePrice';
import { priceFormat } from '../tools';

import { BudgetSummaryLinePricesComponent } from './types';

export const BudgetSummaryLinePrices: FC<BudgetSummaryLinePricesComponent> = (
  { article, disabled },
) => {
  const { t } = useTranslation();
  const [editMode, seteditMode] = useState<boolean>(false);

  const displayPrice = useMemo(
    () => {
      const invoiceAmountInfo = article.questionCode
      && questionsWithMinInvoiceAmount.find((i) => i.questionCode === article.questionCode);

      if (invoiceAmountInfo
         && article.amount < invoiceAmountInfo.minInvoiceAmount) {
        return (article.price || 0) * invoiceAmountInfo.minInvoiceAmount;
      }

      return (article.price || 0) * article.amount;
    },
    [article],
  );

  return (
    <div>
      {editMode && (
      <ArticlePrice
        article={article}
        onCancel={() => seteditMode(false)}
        onPriceUpdated={() => {
          seteditMode(false);
        }}
      />
      )}

      {!editMode && !disabled && (
      <Tooltip
        key="edit-price"
        className="f-budget-summary-actions-group-item"
        content={t('budget.wizard.summary.priceEdit')}
      >
        <span
          role="presentation"
          onClick={() => {
            seteditMode(true);
          }}
          onKeyDown={() => false}
        >
          {priceFormat(displayPrice)}
        </span>
      </Tooltip>
      )}

      {!editMode && disabled && (
      <span
        role="presentation"
      >
        {priceFormat(displayPrice)}
      </span>
      )}
    </div>
  );
};
